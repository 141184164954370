<!--
 * @Author: 艾斯
 * @Date: 2020-02-19 12:41:09
 * @LastEditTime: 2020-03-04 10:51:24
 * @LastEditors: Please set LastEditors
 * @Description: 反馈成绩
 * @FilePath: /vue/yunxue/src/views/task/Feedback.vue
 -->
<template>
  <div id="main">
    <div>{{data}}</div>
    <div class="main">
      <van-sticky>
        <div class="van-hairline--bottom">
          <h4>{{ name }}</h4>
        </div>
      </van-sticky>
      <van-cell v-for="(item, index) in list" :key="index">
        <template slot="title">
          <div class="cell_title">
            <van-row>
              <van-col span="3">{{ item.showNum }}</van-col>
              <van-col span="21" class="maximg" v-if="item.type == 'empty_spaces'">
                答案:
                <span class="mr5" v-html="item.answer"></span>
              </van-col>
            </van-row>
          </div>
        </template>
        <template slot="label">
          <div class="cell_desc">
            <van-row class="mb10">
              <van-col span="6">我的作答：</van-col>
              <van-col span="18">
                <van-checkbox-group
                  v-model="item.studentAnswer"
                  direction="horizontal"
                  v-if="item.type == 'multiple_many'"
                  :disabled="correctable"
                >
                  <van-checkbox
                    v-for="(rs, key) in item.options"
                    :key="key"
                    :name="rs.value"
                  >{{ rs.value }}</van-checkbox>
                </van-checkbox-group>
                <van-radio-group
                  v-model="item.studentAnswer"
                  direction="horizontal"
                  v-else
                  :disabled="correctable"
                >
                  <van-radio v-for="(rs, key) in item.options" :key="key" :name="rs.value">
                    <van-icon
                      class-prefix="my-icon"
                      name="dui"
                      v-if="rs.key == 'allRight' && item.correctable=='studentCorrecte'"
                    />
                    <van-icon
                      class-prefix="my-icon"
                      name="bandui"
                      v-else-if="rs.key == 'halfRight' && item.correctable=='studentCorrecte'"
                    />
                    <van-icon
                      class-prefix="my-icon"
                      name="cuo"
                      v-else-if="rs.key == 'allWrong' && item.correctable=='studentCorrecte'"
                    />
                    <span v-if="item.type=='multiple_one'">{{ rs.key }}</span>
                  </van-radio>
                </van-radio-group>
              </van-col>
            </van-row>
            <van-row v-if="item.type=='empty_spaces'">
              <van-col span="6"></van-col>
              <van-col span="18">
                <van-image
                  width="80"
                  height="80"
                  :src="item.imgUrl"
                  style="margin: 0 15px -10px 0;"
                />
                <van-button
                  plain
                  :loading="item.butLoading"
                  type="primary"
                  size="small"
                  @click="chooseImage(index)"
                >上传图片</van-button>
              </van-col>
            </van-row>
          </div>
        </template>
      </van-cell>
    </div>

    <div class="footer">
      <div class="foot">
        <van-button type="info" :disabled="correctable" block @click="butClick">提交</van-button>
      </div>
    </div>
    <div class="loading" v-show="loading">
      <van-loading vertical color="#1989fa" size="50" text-size="16px">加载中...</van-loading>
    </div>
  </div>
</template>
<script>
import { taskApi, wxApi } from '@/api/index'
import { replaceInfo } from '@/common/index'
import wx from 'weixin-js-sdk'
export default {
  data() {
    return {
      loading: true,
      studentId: this.$route.query.studentId,
      testId: this.$route.query.testId,
      classId: this.$route.query.classId,
      name: '',
      list: [],
      correctable: true,
      appId: 'wx61b727cc8f60e00a',
      data: ''
    }
  },
  mounted() {
    this.getWxCongig()
    this.getList()
  },
  methods: {
    // 获取微信配置
    getWxCongig() {
      let params = {
        url: window.location.href,
        appId: this.appId
      }
      wxApi.getSignature(params).then(res => {
        if (res.success) {
          wx.config({
            appId: this.appId, // 必填，公众号的唯一标识
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.noncestr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名
            jsApiList: ['chooseImage', 'uploadImage', 'getLocalImgData']
          })
        }
      })
    },
    // 拍照或选择相册
    chooseImage(index) {
      let that = this
      wx.chooseImage({
        count: 1, // 默认9
        sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['album', 'camera'],
        success: function(res) {
          that.list[index]['butLoading'] = true
          setTimeout(() => {
            wx.uploadImage({
              localId: res.localIds.toString(), // 需要上传的图片的本地ID，由chooseImage接口获得
              isShowProgressTips: 1, // 默认为1，显示进度提示
              success: function(rs) {
                // iOS WKWebview 下使用
                if (window.__wxjs_is_wkwebview) {
                  wx.getLocalImgData({
                    localId: res.localIds.toString(), // 图片的localID
                    success: function(rss) {
                      that.list[index]['imgUrl'] = rss.localData
                    },
                    fail: function(rss) {
                      alert(JSON.stringify(rss))
                    }
                  })
                } else {
                  that.list[index]['imgUrl'] = res.localIds
                }
                that.list[index]['imgId'] = rs.serverId
                that.list[index]['butLoading'] = false
              },
              fail: function(rs) {
                alert(JSON.stringify(rs))
              }
            })
          }, 100)
        }
      })
    },
    textChange() {
      setTimeout(() => {
        window.MathJax.Hub.Queue([
          'Typeset',
          window.MathJax.Hub,
          document.getElementById('main')
        ])
        this.loading = false
      }, 500)
    },
    getList() {
      let params = {
        studentId: this.studentId,
        testId: this.testId,
        classId: this.classId
      }
      taskApi.getStudentTestPaper(params).then(res => {
        if (res.success) {
          this.correctable = !res.data.correctable
          this.name = res.data.name
          // 补全图片路径
          res.data.data = replaceInfo(res.data.data)

          res.data.data.forEach(item => {
            // 如果填空题并且有多个空格，按空格增加行数
            if (item.type == 'empty_spaces' && item.answer.length > 1) {
              item.answer.forEach((rss, key) => {
                let studentAnswer = []
                let obj = {
                  partitionid: item.partitionid,
                  showNum: `${item.showOrder}(${key + 1})`,
                  showOrder: item.showOrder,
                  type: item.type,
                  answer: rss,
                  options: item.options,
                  studentAnswer: studentAnswer,
                  multiple: key == 0 ? false : true,
                  imgUrl: '',
                  imgId: '',
                  butLoading: false,
                  butShow: false
                }
                this.list.push(obj)
              })
            } else {
              item.answer = item.answer.join(',')
              item.showNum = item.showOrder + '.'
              item.imgUrl = ''
              item.imgId = ''
              item.butLoading = false
              item.butShow = false
              this.list.push(item)
            }
          })
          this.textChange()
        }
      })
    },
    butClick() {
      let message = '是否确定提交？提交后不能撤回！'
      for (let item of this.list) {
        if (item.studentAnswer.length == 0) {
          message = '您还有未批改的题目，是否确定提交？提交后不能撤回！'
          break
        }
      }
      this.$dialog
        .confirm({
          message: message
        })
        .then(() => {
          let data = []
          this.list.forEach(item => {
            if (item.multiple) {
              let i = data.length - 1
              if (!Array.isArray(item.studentAnswer)) {
                data[i]['studentAnswer'] = [
                  ...data[i]['studentAnswer'],
                  ...[item.studentAnswer]
                ]
              } else {
                data[i]['studentAnswer'].push(0)
              }
              data[i]['studentAnswerMediaID'] = [
                ...data[i]['studentAnswerMediaID'],
                ...[item.imgId]
              ]
            } else {
              let answer = ''
              let imgId = []
              if (item.type == 'empty_spaces') {
                answer = !Array.isArray(item.studentAnswer)
                  ? [item.studentAnswer]
                  : [0]
                imgId.push(item.imgId)
              } else {
                answer = !Array.isArray(item.studentAnswer)
                  ? [item.studentAnswer]
                  : ['']
              }
              let obj = {
                partitionid: item.partitionid,
                showOrder: item.showOrder,
                studentAnswer: answer,
                studentAnswerMediaID: imgId
              }
              data.push(obj)
            }
          })
          let params = {
            studentId: this.studentId,
            testId: this.testId,
            classId: this.classId,
            appId: this.appId,
            data: data
          }
          this.data = JSON.stringify(params)
          this.loading = true
          taskApi.postCorrectionResults(params).then(res => {
            if (res.success) {
              this.loading = false
              this.correctable = true
              this.$dialog
                .alert({
                  message: '批改成功'
                })
                .then(() => {
                  this.$router.push({
                    name: 'titleList',
                    query: { testId: this.testId }
                  })
                })
            }
          })
        })
        .catch(() => {
          // on cancel
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.mr5 {
  margin-right: 5px;
}
.mb10 {
  margin-bottom: 10px;
}
.maximg {
  ::v-deep img {
    max-width: 100%;
  }
}
.main {
  padding-bottom: 64px;
  h4 {
    margin: 0;
    padding: 12px;
    background-color: #fff;
  }
}
.cell_title {
  margin: 7px 0;
}
.cell_desc {
  padding-top: 10px;
  font-size: 16px;
  ::v-deep  .van-radio__label,
  ::v-deep  .van-checkbox__label {
    color: #969799;
    padding: 0 5px;
  }
  ::v-deep  .van-radio__icon,
  ::v-deep  .van-checkbox__icon {
    .van-icon {
      display: none;
    }
  }
  ::v-deep  .van-radio__icon--checked + .van-radio__label,
  ::v-deep  .van-checkbox__icon--checked + .van-checkbox__label {
    color: #fff;
    background-color: #1989fa;
    padding: 0 5px;
  }
}
.footer {
  background-color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  .foot {
    padding: 10px 16px;
  }
}
</style>
